import React from 'react';
import './Proyectos.css'
import CardSis from '../cardsis/CardSis';

const Proyectos = () => 
{
    return(
        <div className="container-proyectos">
            <CardSis/>
        </div>
    )
}

export default Proyectos